import React, { useRef, useEffect } from "react";
import { handleBeforeunLoad } from "../../../utils/mixpanelEvents/mixpanelSignupFormEvent";
import usePageSignupPageStore from "../../../sp-state/usePageSignupPageStore";

const SingUpFormRecoilWrapper: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const { timeStart, timeEnd, setTimeStart, resetPageState } =
    usePageSignupPageStore(state => state);
  const pageState = useRef({ timeStart, timeEnd });
  const firstRender = useRef(true);

  useEffect(() => {
    onFirstRender();

    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFirstRender = () => {
    if (firstRender.current) {
      window.addEventListener("load", handleLoad);
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    firstRender.current = false;
  };

  const handleLoad = () => {
    setTimeStart(Date.now());
  };

  const onBeforeUnload = () => {
    handleBeforeunLoad(pageState.current);
    resetPageState();
  };

  return <>{children}</>;
};

export default SingUpFormRecoilWrapper;
