import * as React from "react";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import HipaaCompliantLight from "../../components/SVGIcons/Hipaa/HipaaCompliantLight";
import HiTrustCertified from "../../components/SVGIcons/Hipaa/HiTrustCertified";
import { formHeader } from "@types";
import { Cookie } from "../../utils/Cookie";
import SignUpFormMobileContainer from "../SignUpFormMobileContainer";

import styles from "./SignUpFormContainer.module.scss";

type SignUpFormContainerType = {
  formData: formHeader & { formAlterSubtitle: string };
  subtitleMarginTop?: number;
  submitedUserEmail: string;
  smartDisplayToggle: "on" | "off";
  smartDisplayOptions: {
    option: string;
  }[];
};

const SignUpFormContainer: React.FC<SignUpFormContainerType> = ({
  formData,
  submitedUserEmail,
  smartDisplayToggle,
  smartDisplayOptions,
  subtitleMarginTop = 0,
}) => {
  const { formSubtitle, formTitle } = formData;

  const subtitle = Cookie.getCookie("IS_PARTNER")
    ? formData.formAlterSubtitle
    : formSubtitle;

  return (
    <div
      data-testid="section:FormContainer"
      className={`${styles.mainSignUpFormContainer} ${styles.mainSignUpFormContainerV2}`}
    >
      <div className={`${styles.SignUpFormContainer}`}>
        <div className="text--alignment--center pad-bottom--24">
          <h1 className={styles.formTitle}>{formTitle}</h1>
          <p
            className={styles.formSubtitle}
            dangerouslySetInnerHTML={{ __html: subtitle }}
            style={{ marginTop: subtitleMarginTop }}
          />
        </div>

        <SignUpForm
          page="SignUp"
          submitedUserEmail={submitedUserEmail}
          smartDisplayToggle={smartDisplayToggle}
          smartDisplayOptions={smartDisplayOptions}
        />

        <section className="text--alignment--center">
          <div className={styles.signUpBrands}>
            <HipaaCompliantLight />
            <HiTrustCertified />
          </div>
          <small className="primary--grey-text">
            © {new Date().getFullYear()} SimplePractice |{" "}
            <a
              className="sign-up__links"
              target="_blank"
              aria-label="Terms and Conditions"
              href="https://www.simplepractice.com/terms"
              rel="noreferrer"
            >
              Terms
            </a>{" "}
            |{" "}
            <a
              className="sign-up__links"
              target="_blank"
              aria-label="Privacy Policy"
              href="https://www.simplepractice.com/privacy"
              rel="noreferrer"
            >
              Privacy
            </a>
          </small>
        </section>
      </div>
    </div>
  );
};

export default SignUpFormContainer;
