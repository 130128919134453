import { useEffect, useState } from "react";
import { mxpPageViewed } from "../utils/mixpanelEvents/mixpanelEvents";

type UseIsClientType = (isTracked?: boolean) => {
  isClient: boolean;
};

const useIsClient: UseIsClientType = isTracked => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);

    if (isTracked) {
      mxpPageViewed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isClient,
  };
};

export default useIsClient;
