import * as React from "react";
import { PageProps } from "gatsby";
import SimpleDefaultLayout from "../../layouts/SimpleDefaultLayout";
import SignUpFormContainer from "../../containers/SignUpFormContainer/SignUpFormContainer";
import { useEffect } from "react";
import { graphql } from "gatsby";
import { YoastSEOType } from "@types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import SingUpFormRecoilWrapper from "../../containers/SignUpFormContainer/SignUpFormRecoil";
import { mxpSignupFirstView } from "../../utils/mixpanelEvents/mixpanelEvents";
import SPURLSearchParams from "../../services/SPURLSearchParams.class";
import SignUpHeader from "../../components/SignUpHeader/SignUpHeader";
import { deIndexSeo } from "../../utils/deIndexSeo";

type SeoData = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      smartDisplay: {
        smartDisplayToggle: "on" | "off";
        smartDisplayOptions: {
          option: string;
        }[];
      };
      alternativeHeaderC: {
        alternativeHeaderLink: string;
        alternativeHeaderLinkText: string;
      };
      securityCopy: {
        securityCopy: string;
      };
    };
  };
};

const SignUpTrialPage: React.FC<PageProps<SeoData>> = ({ location, data }) => {
  const {
    wpPage: {
      template: {
        smartDisplay: { smartDisplayToggle, smartDisplayOptions },
        alternativeHeaderC: {
          alternativeHeaderLink,
          alternativeHeaderLinkText,
        },
      },
    },
  } = data;
  const { wpPage } = data;

  const formData = {
    formSubtitle: "Free for 30 days, no credit card required",
    formTitle: "Get Started with SimplePractice",
    formAlterSubtitle: "No credit card required",
  };

  const expFormData = {
    formTitle: "Get Started with SimplePractice",
    formSubtitle: `Free, 30-day trial of Plus plan. No credit card required. <br class="hide-for-small"> Choose from plans starting at $29/month after your trial.`,
    formAlterSubtitle: `Free trial of Plus plan. No credit card required. <br class="hide-for-small"> Choose from plans starting at $29/month after your trial.`,
  };

  let submitedUserEmail: string = "";

  if (location.state) {
    submitedUserEmail = location.state["email"];
  }

  useEffect(() => {
    const isConsultcall = Boolean(
      SPURLSearchParams.get(location.search, "consultcall")
    );
    mxpSignupFirstView("TESTGROUP_ID", isConsultcall);
  }, [location.search]);

  return (
    <SimpleDefaultLayout isTracked={false}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <SingUpFormRecoilWrapper>
        <SignUpHeader
          alternativeHeaderLink={alternativeHeaderLink}
          alternativeHeaderLinkText={alternativeHeaderLinkText}
        />

        <SignUpFormContainer
          formData={formData}
          submitedUserEmail={submitedUserEmail}
          smartDisplayToggle={smartDisplayToggle}
          smartDisplayOptions={smartDisplayOptions}
        />
      </SingUpFormRecoilWrapper>
    </SimpleDefaultLayout>
  );
};

export default SignUpTrialPage;

export const query = graphql`
  query SignUpTrailTemplate($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpSignUpTrialTemplate {
          alternativeHeaderC {
            alternativeHeaderLink
            alternativeHeaderLinkText
          }
          smartDisplay {
            smartDisplayToggle
            smartDisplayOptions {
              option
            }
          }
        }
      }
    }
  }
`;
