import React from "react";
import { AlternativeHeaderType } from "@types";
import { Link } from "gatsby";
import styles from "../AlternativeHeaderSignUpForm/AlternativeHeader.module.scss";

const SignUpHeader: React.FC<AlternativeHeaderType> = ({
  alternativeHeaderLink,
  alternativeHeaderLinkText,
}) => {
  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const mixPanelData = {
      sp_destination: e.currentTarget.href,
      sp_link_text: e.currentTarget.textContent,
    };

    window.trackMixpanelEvent("user: link clicked", mixPanelData);
  };

  const headerV2 = styles.containerSignUpHeaderV2;

  return (
    <div
      className={`${styles.containerAlternativeHeader} ${styles.containerSignUpHeader} ${headerV2}`}
    >
      <Link to="/">
        <svg className={styles.logo}>
          <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
        </svg>
      </Link>
      <div className={styles.containerPractitioner}>
        {alternativeHeaderLink && alternativeHeaderLinkText && (
          <>
            <img
              className={`${styles.healthBagIcon} ${styles.healthBagSignUp}`}
              src="/icons/user.svg"
              alt="User Icon"
            />
            <button
              type="button"
              onClick={handleLink}
              className={styles.signUpButton}
            >
              <Link to={alternativeHeaderLink}>
                {alternativeHeaderLinkText}
              </Link>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignUpHeader;
