import React, { useEffect } from "react";

import { GrowsumoType } from "@types";
import { MainProvider } from "../../contexts/MainContext";
import useGtmPageChange from "../../hooks/useGtmPageChange";
import useIsClient from "../../hooks/useIsClient";
import generateUuId from "../../utils/generateUuId";
import { mxpPageViewed } from "../../utils/mixpanelEvents/mixpanelEvents";

import "../main.scss";

declare global {
  interface Window {
    mixpanelTrialSent: boolean;
    trackMixpanelEvent: (
      event: string,
      data?: {
        [key: string]: any;
      }
    ) => void;
    grecaptcha: any;
    dataLayer: {
      push: (someObj: Object) => boolean;
    };
    gtag: any;
    fbq: any;
    google_conversion_first_name: string;
    google_conversion_last_name: string;
    google_conversion_email: string;
    google_conversion_phone_number: string;
    Grnhse: {
      Iframe: {
        load: (jobId?: number) => void;
      };
    };
    growsumo?: GrowsumoType;
    Osano: {
      cm: {
        showDrawer: (s: string) => void;
      };
    };
  }
}

const SimpleDefaultLayout: React.FC<Layouts.Simple> = ({
  children,
  isTracked = true,
}) => {
  const { isClient } = useIsClient(isTracked);
  const uuid = generateUuId();

  useEffect(() => {
    if (isTracked) {
      mxpPageViewed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useGtmPageChange(uuid);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        <div>
          <main>{children}</main>
        </div>
      </React.Fragment>
    </MainProvider>
  );
};

export default SimpleDefaultLayout;
